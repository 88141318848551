






import { Component, Vue } from 'vue-property-decorator'

import InstructionsTable from '@/partials/tables/Medical/Instructions.vue'

@Component({
  components: {
    InstructionsTable
  }
})
export default class MedicalInstructions extends Vue {

}
